import config from "config/appConfig";

import { MembersModel } from "shared/types";
import { Receipt } from "shared/types/TransactionModel";
import { IStateProps } from "utils/StateOf";

import createStore from "utils/createStore";

import { SelectedMemberType } from "./../../types/RewardedMemberType";

const localStorageKey = `${config.GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${config.HOOK_STORAGE_KEYS.LOYALTY_DATA}`;

export interface HistoryDialogType {
	open: boolean;
	member: MembersModel | null;
	history: Receipt[] | null;
}

export interface LoyaltyContextValues {
	loading: boolean;
	historyDialog: HistoryDialogType;
	awardDialog: {
		open: boolean;
		members: SelectedMemberType[];
	};
	selectedMembers: SelectedMemberType[];
	historyCount: number;
}

export const initialState: IStateProps<LoyaltyContextValues> = {
	loading: false,
	awardDialog: {
		open: false,
		members: []
	},
	historyDialog: {
		open: false,
		member: null,
		history: null
	},
	selectedMembers: [],
	historyCount: 0
};

export default createStore<LoyaltyContextValues>({
	initialState,
	persistant: true,
	persistantName: localStorageKey
});
