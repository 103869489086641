import { useMemo } from "react";

import { API_URLS } from "modules/LoyaltyRewards/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";
import BaseConverter from "shared/services/converters/baseConverter";

const useLoyaltyRewardsService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			awardMembers: async (personaIds: number[], amount: number) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.post(API_URLS.AWARD_MEMBERS, {
						personaIds,
						amount
					})
				);
			}
		}),
		[actions]
	);
};

export default useLoyaltyRewardsService;
